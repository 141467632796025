body {
  height: 100%;
  width: 100vw;
  max-width: 100%;
  margin: 0;
  font-family: "Manrope", serif;
  font-style: normal;
  background-color: rgba(26, 2, 62, 1);
  color: white;
}

a {
  width: 100%;
  max-width: 400px;
  text-decoration: none;
  color: inherit;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  position: relative;

}

.backgroundLight {
  backdrop-filter: blur(191.56922912597656px);
  background: rgba(88, 61, 245, 1);
  z-index: -10;
  border-radius: 50%;
  filter: blur(100px);
}

.lightOne {
  position: fixed;
  width: 261px;
  height: 400px;
  top: 30%;
  left: 60%;
  backdrop-filter: blur(191.56922912597656px);
}

.lightTwo {
  position: fixed;
  width: 300px;
  height: 370px;
  top: 55%;
  left: -30%;
  backdrop-filter: blur(220px);
}

.devText {
  display: flex;
  max-width: 400px;
  text-align: center;
  margin: 0 auto 32px;
}

.onlineText {
  text-align: center;
  margin-top: 41px;
  margin-bottom: 28px;
}

.grayText {
  color: rgba(191, 195, 222, 1);
}

.boldText {
  font-weight: 700;
}

.smallText {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
}

.smallMiddleText {
  font-size: 16px;
  line-height: 20.8px;
}

.middleText {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.bigText {
  font-size: 22px;
  line-height: 28.6px;
}

.hugeText {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  margin-bottom: 4px;
}

.topText {
  padding: 16px 0 8px;
  text-align: center;
}

.topImg {
  position: relative;
  margin-left: -10%;
  width: 260px;
  height: 196px;
}

.bottomText {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  padding: 16px 0 8px;
  text-align: center;
  margin-bottom: 24px;
}

.bottomTextNext {
  text-align: center;
  margin-bottom: 41px;
}

.howItWorks {
  color: rgba(161, 168, 222, 1);
  margin-bottom: 20px;
  text-align: center;
}

.worksBlock {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-bottom: 20px;
}

.workBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1px;
  width: 104px;
  height: 90px;
  border-radius: 12px;
  background: rgba(21, 16, 33, 0.5);
  backdrop-filter: blur(4px);
}

.workBoxImg {
  width: 40px;
  height: 40px;
}

.middleImg {
  display: block;
  margin: auto;
  width: 255.81px;
  height: 278px;
  margin-bottom: 16px;
}

.rocketImg {
  display: block;
  margin: auto;
  width: 298.5px;
  height: 199px;
}

.createBlock {
  display: flex;
  max-height: 62px;
  width: calc(100% - 40px);
  max-width: 400px;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  background: rgba(21, 16, 33, 0.5);
  backdrop-filter: blur(4px);
  margin: 0 auto 20px;
  padding: 8px 16px 8px 16px;
}

.createBlockTextContainer {
  display: flex;
  gap: 16px;
  align-items: center;
}

.createBlockImg {
  width: 36px;
  height: 36px;
}

.createTextBlock {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
}

.buttonBig {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 123, 35, 1);
  line-height: 16px;
  color: white;
  padding: 8px;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: center;
  height: 56px;
  width: 100%;
  max-width: 335px;
  text-transform: uppercase;
  margin: auto;
}

.buttonSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  background: rgba(255, 123, 35, 1);
  border: 0.3px solid rgba(157, 96, 255, 1);
  font-size: 16px;
  line-height: 16px;
  color: white;
  padding: 8px 12px 8px 12px;
  border-radius: 100px;
}

.buttonTransparent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 400px;
  height: 56px;
  background: transparent;
  text-transform: uppercase;
  font-weight: 600;
  border: 2px solid rgba(88, 61, 245, 1);
  border-width: 2px;
  border-color: rgba(88, 61, 245, 1);
  border-style: solid;
  font-size: 16px;
  line-height: 19.2px;
  color: white;
  padding: 8px 12px 8px 12px;
  border-radius: 16px;
  border: 2px 0px 0px 0px;
  margin: 0 auto 20px;
}


.textArr {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 400px;
  margin: 0 auto 24px;
  text-align: start;
  font-size: 16px;
  font-weight: 800;
  line-height: 20.8px;
  padding-inline-start: 20px;
}

.launchBlock {
  display: flex;
  flex-grow: 1;
  width: calc(100% - 40px);
  max-height: 52px;
  max-width: 400px;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  background: rgba(75, 63, 145, 0.5);
  backdrop-filter: blur(4px);
  margin: 16px auto 48px;
  padding: 8px 16px 8px 16px;
  font-weight: 800;
  white-space: nowrap;
}

.adText {
  font-size: 14px;
  font-weight: 800;
  line-height: 19.12px;
  text-align: left;
}
